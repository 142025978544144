import axios from 'axios';
import { getToken } from '../utils/token';

// const host = "http://localhost:3002/";
// const host = "https://menusapiv2.edkamm.com/";
const host = "https://apiv3.themenus.digital/";

const token = (typeof window !== 'undefined')? getToken(): null;

axios.defaults.headers.common["authentication-info"] = token;

const call = async(method, path, data) => {
    const res = await axios[method](host+path, data);
    return res.data;
}

const setAuthHeader = token => {
    axios.defaults.headers.common["authentication-info"] = token;
}

export const apiService = {
    call,
    setAuthHeader,
}